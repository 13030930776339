
<template>
	<div class="diarioDeBordo py-5">
		<div class="welcomeMobile" v-if="this.sizeWindow <= 767">
			<router-link to="/" class="imgWelCont">
			<img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
			</router-link>
			<div class="txtWelCont">
				<p>Despesas</p>
			</div>
		</div>
		<div class="container">
			<div class="col-md-12" :style="load ? 'height:250px;':'min-height:100%;'">
				<loader color="#e0a638" size="100" v-if="load" />
				<div class="col-md-12">
					<transition name="fade">
						<div class="col-md-12 my-3" v-show="!load">
							<div class="row align-items-center">
								<div class="col-md-2 px-0" v-if="this.sizeWindow > 767">
									<p class="titulo_dash mb-0">Despesa</p>
								</div>
								<div class="col-md-3 ml-auto pr-0">
									<button
										class="btn_default d-flex justify-content-center align-items-center"
										@click.prevent="showObs()"
									>
										<i class="fas fa-plus-circle mr-1"></i>Adicionar despesa
									</button>
								</div>
							</div>
						</div>
					</transition>
					<transition name="fade">
						<div class="col-md-12" v-show="!load">
							<div class="row" v-if="iventary !=''">
								<div v-if="this.sizeWindow > 767" class="col-md-2">
									<p class="titulo_dash mb-0 nav">Data</p>
								</div>
								<div v-if="this.sizeWindow > 767" class="col-md-2">
									<p class="titulo_dash mb-0 nav">Usuário</p>
								</div>
								<div v-if="this.sizeWindow > 767" class="col-md-2">
									<p class="titulo_dash mb-0 nav">Item</p>
								</div>
								<div v-if="this.sizeWindow > 767" class="col-md-2">
									<p class="titulo_dash mb-0 nav">Valor</p>
								</div>
							</div>
							<transition name="vertical" v-else>
								<p
									class="my-5 text-center w-100"
									style="font-size:16px; color:#ffff;"
								>Ainda não existem solicitações de despesa</p>
							</transition>
						</div>
					</transition>
					<newDespesa class="observer" @getInventay="getInventay" @showObs="showObs" />
					<transition-group name="list-complete" tag="div">
						<div
							v-show="!load && item"
							v-for="(item , index) in iventary"
							:key="item.id"
							class="list-complete-item"
						>
							<div class="col-md-12 box py-2 my-2" id="allItensCont">
								<div class="row align-items-center h-50px">
									<div class="col-md-2" id="nameItemCont">
										<p v-if="sizeWindow <= 767" class="descricao mb-0">Data</p>
										<p class="descricao mb-0">{{getDate(item.creationTime)}}</p>
									</div>
									<div class="col-md-2" id="nameItemCont">
										<p v-if="sizeWindow <= 767" class="descricao mb-0">Usuário</p>
										<p class="descricao mb-0">{{item.user ? item.user.name : 'Não informado'}}</p>
									</div>
									<div class="col-md-2" id="nameItemCont">
										<p v-if="sizeWindow <= 767" class="descricao mb-0">Item</p>
										<p class="descricao mb-0">{{item.item}}</p>
									</div>
									<div class="col-md-2" id="nameItemCont">
										<p v-if="sizeWindow <= 767" class="descricao mb-0">Valor</p>
										<p class="descricao mb-0">{{item.price | currency}}</p>
									</div>
									<div class="col-md-2 ml-auto">
										<div class="row alinhamento justify-content-end">
											<div class="col-md-3 px-0 text-right" id="iconEditRemoveCont">
												<p v-if="sizeWindow <= 767" class="descricao mb-0">Editar</p>
												<div class="iconCont"><i
													class="fas fa-pen"
													:class="activeEdit == index ? 'active' :''"
													@click.passive="showEdit(index)"
												></i></div>
											</div>
											<div class="col-md-3 px-0 text-right" id="iconEditRemoveCont">	
												<p v-if="sizeWindow <= 767" class="descricao mb-0">Excluir</p>											
												<div class="iconCont"><i class="fas fa-times-circle delete" @click.prevent="deleteLog(item.id)"></i></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<editDespesa
								class="editItem"
								:class="`editItem${index}`"
								:price.sync="item.price"
								:item.sync="item.item"
								:creationTime.sync="item.creationTime"
								:id.sync="item.id"
								:activeEdit.sync="activeEdit"
								@getInventay="getInventay"
							/>
						</div>
					</transition-group>
				</div>
			</div>
		</div>
    <modalDegustacao/>
	</div>
</template>

<script>
	import newDespesa from "@/components/despesas/newDespesa";
	import editDespesa from "@/components/despesas/editDespesa";
  import modalDegustacao from "@/components/modal/modalDegustacao";
	import HTTP from "@/api_system";
	import loader from "@/components/utils/_Load";
	export default {
		components: {
			newDespesa,
			loader,
			editDespesa,
      modalDegustacao
		},
		data() {
			return {
				active: null,
				activeEdit: null,
				iventary: [],
				load: true,
				sizeWindow: 0,
			};
		},
		mounted() {
			this.sizeWindow = $(window).width();
			this.getInventay();
		},
		methods: {
			getInventay() {
				HTTP.get("/services/app/Expense/GetAll", {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`,
					},
				})
					.then((response) => {
						this.load = false;

						this.iventary = response.data.result.sort(function (a, b) {
							if (a.creationTime > b.creationTime) {
								return -1;
							}
							if (a.creationTime < b.creationTime) {
								return 1;
							}
							return 0;
						});
					})
					.catch((error) => {
						this.load = false;
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`,
						});
					});
			},
			showDp(index) {
				if ($(".dropdownCp").is(":visible")) {
					$(".dropdownCp").velocity("slideUp", {
						display: "none",
						duration: 500,
					});
				}
				var show;
				if ($(`.dropdownCp${index}`).is(":visible")) {
					show = "slideUp";
					this.active = null;
				} else {
					show = "slideDown";
					this.active = index;
				}

				$(`.dropdownCp${index}`).velocity(show, {
					display: `${show == "slideUp" ? "none" : "block"}`,
					duration: 500,
				});
			},

			showObs() {
				if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
          $("#modalDegustacao").modal("show");;
        } else {
          var show;
          this.activeEdit = null;
          $(".editItem").velocity("fadeOut", {
            display: "none",
            duration: 500,
          });
          this.activeEdit = null;
          if ($(`.observer`).is(":visible")) {
            show = "fadeOut";
          } else {
            show = "fadeIn";
          }
          $(`.observer`).velocity(show, {
            display: `${show == "fadeOut" ? "none" : "block"}`,
            duration: 500,
          });
        }
			},
			showEdit(index) {
        if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
          $("#modalDegustacao").modal("show");;
        } else {
            $(".observer").velocity("fadeOut", {
            display: "none",
            duration: 500,
          });
          if ($(".editItem").is(":visible")) {
            $(".editItem").velocity("fadeOut", {
              display: "none",
              duration: 500,
            });
          }
          var show;
          if ($(`.editItem${index}`).is(":visible")) {
            show = "fadeOut";
            this.activeEdit = null;
          } else {
            show = "fadeIn";
            this.activeEdit = index;
          }

          $(`.editItem${index}`).velocity(show, {
            display: `${show == "fadeOut" ? "none" : "block"}`,
            duration: 500,
          });
        }
			},

			deleteLog(val) {
        if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
          $("#modalDegustacao").modal("show");
        } else {
          HTTP.delete(`services/app/Expense/Delete?Id=${val}`, {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`,
					},
          }).then((response) => {
            this.getInventay();
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Item excluido`,
            });
          });
        }
			},
			getDate(val) {
				var data = new Date(val);
				return `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
			},
		},
	};
</script>

<style scoped>
.delete:hover {
	color: rgb(230, 121, 121) !important;
}
.btn_default {
	height: 40px;
	background-color: #e0a638 !important;
	border: none !important;
}
.btn_default:hover {
	border: none !important;
	background-color: #f38235 !important;
}
.h-50px {
	height: 50px;
}
.btn_default i {
	color: #fff !important;
}
.dropdownCp {
	display: none;
}
.observer {
	display: none;
}
.editItem {
	display: none;
}
.diarioDeBordo {
	background: #2474b2;
}
.nav {
	font-size: 12px;
}
.box {
	background: #357eb8;
	box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
.descricao {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: 17px;
}
.active {
	color: #fff !important;
}
.fas,
.far {
	color: #175d93;
	font-size: 20px;
	cursor: pointer;
	transition: all 0.2s linear;
}

.far:hover {
	color: #fff;
}
.fas:hover {
	color: #fff;
}
.alinhamento {
	padding-right: 15px;
	padding-left: 15px;
}
@media screen and (max-width:767px){
  
  .welcomeMobile{
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;
	  width: 100%;
	  background: #217ABF;
	  height: 40px;
	  margin-bottom: 3%;
	}
	.imgWelCont{
	  width: 51%;
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	}
	.txtWelCont{
	  width: 77%;
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	}
	.txtWelCont p{
	  margin-bottom: 0;
	  font-style: normal;
	  font-weight: 700;
	  font-size: 16px;
	  line-height: 24px;
	  color: #FFFFFF;
	}
	.imgWelCont img{
	  padding-left: 7%;
	  width: 26%;
	}
	.diarioDeBordo{
		padding-top: 0!important;
	}
	#allItensCont{
		min-height: 265px;
		background: #3F88C1;
		border-radius: 8px;
	}
	#nameItemCont{
		min-height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px #4d98d3 solid;
	}
	.alinhamento{
		margin-top: 2%;
		padding: 0;
	}
	#iconEditRemoveCont{
		min-height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px #4d98d3 solid;
		padding-left: 15px!important;
		padding-right: 15px!important;
	}
	.iconCont{
		width: 50%;
		height: 75%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #5397CC;
		border-radius: 4px;
	}
  }
</style>
