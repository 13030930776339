<template>
	<div class="col-md-12 box my-2">
		<div class="row align-items-center h-100">
			<div class="col-md-3 d-flex align-items-center" id="nameItemCont">
				<p class="descricao mb-0 mr-3">Data</p>

				<input type="date" class="input_default form-control" v-model="creationTimeEdit" />
			</div>
			<div class="col-md-4 d-flex align-items-center" id="nameItemCont">
				<p class="descricao mb-0 mr-3">Item</p>
				<input type="text" class="input_default form-control" v-model="itemEdit" />
			</div>
			<div class="col-md-3 d-flex align-items-center" id="nameItemCont">
				<p class="descricao mb-0 mr-3">Valor</p>
				<money v-bind="money" class="input_default form-control" v-model="priceEdit" />
			</div>

			<div class="col-md-2 d-flex align-items-center justify-content-end ml-auto" v-if="!load">
				<i class="fas fa-times-circle ml-4 exclude" @click.prevent="closeEdit"></i>
				<i class="fas fa-check-circle ml-3 edit" @click.prevent="newLog()"></i>
			</div>
		</div>
	</div>
</template>

<script>
	import HTTP from "@/api_system";
	import { Money } from "v-money";

	export default {
		props: ["price", "id", "item", "activeEdit", "creationTime"],
		components: { Money },
		data() {
			return {
				money: {
					decimal: ",",
					thousands: ".",
					prefix: "R$ ",

					precision: 2,
					masked: false,
				},
				load: false,
				creationTimeEdit: moment(this.creationTime).format("YYYY-MM-DD"),
				priceEdit: this.price,
				itemEdit: this.item,
			};
		},
		mounted() {
		},
		methods: {
			closeEdit() {
				$(".editItem").velocity("fadeOut", {
					display: "none",
					duration: 500,
				});
			},
			newLog() {
				var json;
				this.load = true;
				json = {
					id: this.id,
					item: this.itemEdit,
					price: this.priceEdit,
					creationTime: this.creationTimeEdit,
				};

				HTTP.post("services/app/Expense/CreateOrEdit", json, {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`,
					},
				})
					.then((response) => {
						this.load = false;
						this.$emit("getInventay");

						this.$emit("update:activeEdit", null);
						$(".editItem").velocity("fadeOut", {
							display: "none",
							duration: 500,
						});
					})
					.catch((error) => {
						this.load = false;
					});
			},
		},
	};
</script>

<style scoped>
.box {
	background: #357eb8;
	border: 1px solid #e0a638;
	height: 75px;
}
.input_default {
	height: 45px;
	color: #ffffff;
	text-align: left;
	background-color: #6198c2 !important;
}
.descricao {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
}
.fas,
.far {
	font-size: 20px;
	cursor: pointer;
	transition: all 0.2s linear;
}
.exclude {
	color: #e67979;
}
.edit {
	color: #35b890;
}
@media screen and (max-width:767px){
	.box{
		min-height: 215px;
	}
	#nameItemCont p{
		min-width: 11%;
	}
}
</style>
